@import "utils/vars";
@import "mixins/mixins";

.admin-ingredients {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 32px 0 0;

  &__filters {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    gap: 24px;
  }
}
