@import "utils/vars";

.ingredient {
  &__info {
    display: grid;
    grid-template-columns: 1fr repeat(2, auto);
    gap: 16px;
    align-items: center;

    @media (max-width: $mobile) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__name {
    margin: 0;

    @media (max-width: $mobile) {
      grid-column: 1 / -1;
    }
  }

  &:not(:last-child) {
    margin: 0 0 1rem;
  }
}
