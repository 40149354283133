@import "utils/vars";

.user {
  display: flex;
  align-items: center;
  padding: 20px 32px;
  background-color: #f8f8fa;

  @media (max-width: $mobile) {
    padding: 1rem;
  }

  &:not(:last-child) {
    margin: 0 0 1rem;
  }

  &__avatar-wrap {
    margin: 0 24px 0 0;
  }

  &__avatar {
    object-fit: cover;
    overflow: hidden;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  &__link {
    display: block;
    text-decoration: none;
  }

  &__info {
    flex-grow: 1;
    margin: 0 24px 0 0;
  }

  &__name {
    margin: 0;
    color: $text-color-black;
    font-weight: 400;
    font-size: 24px;
    font-family: $futura-pt-book;
    line-height: 32px;
    letter-spacing: 0.05em;
  }

  &__country,
  &__status {
    margin: 0;
    color: $text-color-grey;
    font-size: 16px;
    font-family: $futura-pt-book;
    line-height: 20px;
    letter-spacing: 0.05em;
  }
}
