@import "utils/vars";

.wrapper {
  display: block;
  width: 100%;
  max-width: 1264px;
  margin: 0 auto;
  padding: 0 32px;

  @media (max-width: $mobile) {
    padding: 0 16px;
  }
}
