@import "utils/vars";

.page-header {
  border-bottom: 1px solid #e0e0e0;

  @media (max-width: $mobile) {
    border-bottom: none;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $mobile) {
      justify-content: flex-start;
      padding: 12px 16px;
    }
  }

  &__logo {
    flex-shrink: 0;

    @media (max-width: $mobile) {
      width: calc(100% - 64px);
    }

    .logo {
      &__img {
        @media (max-width: $tablet) {
          width: 118px;
        }

        @media (max-width: $mobile) {
          width: 100px;
          margin: auto;
        }
      }
    }
  }

  &__main-nav {
    flex-shrink: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}
