@import "utils/vars";
@import "mixins/mixins";

.pagination {
  margin: 0 0 60px;

  &__list {
    @include list-unstyled;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  &__item {
    margin: 0 4px;

    @media (max-width: $mobile) {
      margin: 0;
    }

    &_first-page,
    &_last-page {
      .pagination {
        &__link {
          display: flex;
          width: auto;
        }

        &__label {
          padding: 0 4px;
          white-space: nowrap;

          @media (max-width: $mobile) {
            display: none;
          }
        }
      }
    }

    &_first-page {
      margin: 0 auto 0 0;
    }

    &_last-page {
      margin: 0 0 0 auto;
    }

    &_prev-page {
      margin: 0 4px 0 0;
    }

    &_next-page {
      margin: 0 0 0 4px;
    }

    &_prev-page,
    &_next-page {
      .pagination {
        &__link {
          @media (max-width: $mobile) {
            width: 24px;
          }
        }
      }
    }

    &_disabled {
      opacity: 1;
      visibility: hidden;
      pointer-events: none;
    }
  }

  &__link {
    display: block;
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    padding: 10px 0;
    color: $text-color-grey;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    &_active {
      position: relative;
      color: $orange;

      &:hover,
      &:focus {
        color: $orange;
      }

      &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 16px;
        height: 2px;
        margin: auto;
        border-radius: 2px;
        background-color: $orange;
      }
    }
  }
}
