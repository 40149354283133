.editor {
  .sun-editor,
  .sun-editor-editable {
    font-family: $futura-pt-book;
  }

  .sun-editor-editable {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.05em;
  }
}
