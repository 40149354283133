// Базовые цвета https://colorscheme.ru/color-converter.html

// Colors

$black: #000000;
$white: #ffffff;

$orange: #f5795d;
$red: #ff281e;
$green: #28bc00;
$yellow: #ffc000;

// Семантические цвета

$text-color-black: #272424;
$text-color-grey: #9fa1ab;
$body-bg: $white;
$page-bg: $white;

$border-color: $black;

// Базовая типографика

$font-size: 16px;

$font-size-h1: 40px;
$font-size-h2: 32px;
$font-size-h3: 24px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 16px;

$font-size-sm: 0.75em; // 12px от 16px

$line-height: 1.5;

$typo-margin-vertical: 1em;

$font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family--monospace: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
$futura-pt: "Futura PT", sans-serif;
$futura-pt-book: "Futura PT Book", sans-serif;
$futura-pt-demi: "Futura PT Demi", sans-serif;

// Ширины

$mobile: 767.98px;
$tablet: 991.98px;

// Разное

$border-radius: 8px;
$transition-time: 0.3s;

$retina2-dpi: 192dpi;
$retina2-dppx: 2dppx;

$retina3-dpi: 288dpi;
$retina3-dppx: 3dppx;
