@import "utils/vars";
@import "mixins/mixins";

.collapse {
  &__header {
    padding: 10px 0;
  }

  &__content {
    display: none;
    padding: 10px 0 20px 26px;

    p {
      &:last-child {
        margin: 0;
      }
    }

    &_open {
      display: block;
    }
  }
}
