.form {
  &__fieldset {
    min-width: 0;
    margin: 0;
    padding: 0;
    border: none;
  }

  &__legend {
    display: block;
    float: left;
    width: 100%;
    max-width: 100%;
    margin: 0 0 1rem;
    padding: 0;
    color: currentcolor;
    white-space: normal;

    & + * {
      clear: left;
    }
  }
}
