@import "utils/vars";
@import "mixins/mixins";

.mobile-menu {
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none;
  overflow-y: auto;
  background-color: $white;

  &__wrapper {
    @media (max-width: $mobile) {
      padding: 10px 16px;
    }
  }

  &__main-nav {
    .main-nav {
      &__list {
        flex-direction: column;
      }

      &__link {
        padding: 12px 0;
        font-size: 18px;
        line-height: 24px;

        &_active {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  &_open {
    @media (max-width: $mobile) {
      display: block;
    }
  }
}
