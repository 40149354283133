@import "../utils/vars";

.field-text {
  display: block;

  &__name {
    display: block;
    margin: 0 0 8px;
    color: $text-color-grey;
    font-size: 12px;
    font-family: $futura-pt-book;
    line-height: 16px;
    text-transform: uppercase;
  }

  &__input-wrap {
    position: relative;
    display: block;
    cursor: text;
  }

  &__input {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    min-height: 32px;
    margin: 0;
    padding: 0 0 7px;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #e0e0e0;
    border-left: 0;
    background-color: transparent;
    color: #191919;
    font-size: 18px;
    font-family: $futura-pt-book;
    line-height: 24px;
    letter-spacing: 0.05em;
    transition: border-color 0.3s ease;
    appearance: none;

    &:disabled {
      border-bottom-style: dashed;
      color: $text-color-grey;
      cursor: not-allowed;
    }

    &::placeholder {
      color: $text-color-grey;
    }

    &:focus {
      border-color: $green;
      outline: none;
    }
  }

  &__help-text {
    display: block;
    padding: 8px 0 0;
    color: #191919;
    font-size: 14px;
    font-family: $futura-pt-book;
    line-height: 18px;
  }

  &_valid {
    .field-text {
      &__input {
        border-color: $green;
      }

      &__help-text {
        color: $green;
      }
    }
  }

  &_error {
    .field-text {
      &__input {
        border-color: $red;
      }

      &__help-text {
        color: $red;
      }
    }
  }

  &_floating {
    position: relative;
    display: flex;
    flex-direction: column;

    .field-text {
      &__name {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        font-size: 14px;
        line-height: 18px;
        pointer-events: none;
        transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1);
        transform: translate(0, 4px) scale(1);
        transform-origin: top left;

        & + .field-text {
          &__input-wrap {
            margin: 24px 0 0;
          }
        }
      }

      &__input-wrap {
        margin: 24px 0 0;
      }

      &__input {
        &:first-child {
          &::placeholder {
            color: transparent;
          }

          &:focus,
          &:not(:placeholder-shown) {
            & ~ .field-text {
              &__name {
                transform: translateY(-22px) scale(0.89);
              }
            }
          }
        }
      }
    }
  }
}
