@import "utils/vars";
@import "mixins/mixins";

.main-nav {
  &__list {
    @include list-unstyled;

    display: flex;
    margin: 0;
  }

  &__link {
    position: relative;
    display: block;
    padding: 23px 12px 24px;
    color: $text-color-grey;
    font-size: 18px;
    font-family: $futura-pt-book;
    line-height: 24px;
    text-decoration: none;

    @media (max-width: $tablet) {
      font-size: 16px;
      line-height: 20px;
    }

    &:hover,
    &:focus {
      color: $text-color-black;
    }

    &_active {
      color: $orange;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: -1px;
        left: 0;
        display: block;
        width: 30px;
        height: 2px;
        margin: auto;
        border-radius: 2px;
        background-color: $orange;
      }

      &:hover,
      &:focus {
        color: $orange;
      }
    }
  }
}
