@import "utils/vars";
@import "mixins/mixins";

.product-form {
  margin: 0 0 32px;

  &__fieldset {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 16px;
    margin: 0 0 32px;

    @media (max-width: $mobile) {
      grid-template-columns: auto;
    }

    &_seo {
      grid-template-columns: auto;
    }
  }

  &__legend {
    grid-column: 1/ -1;
    margin: 0 0 8px;
    font-weight: 400;
    font-size: 32px;
    font-family: $futura-pt-book;
    line-height: 24px;
  }

  &__field-text {
    &_name,
    &_description,
    &_ingredients,
    &_amazon-url,
    &_user-requested-name {
      grid-column: 1 / -1;
    }

    &_country,
    &_producer {
      grid-column: span 2;

      @media (max-width: $mobile) {
        grid-column: 1 / -1;
      }
    }
  }

  &__field-select {
    &_category,
    &_subcategory {
      grid-column: span 3;

      @media (max-width: $mobile) {
        grid-column: 1 / -1;
      }
    }

    &_brand {
      grid-column: span 2;

      @media (max-width: $mobile) {
        grid-column: 1 / -1;
      }
    }
  }
}
