@import "utils/vars";
@import "mixins/mixins";

.toolbar {
  margin: 0 0 32px;

  &__wrapper {
    display: flex;
    gap: 20px;

    @media (max-width: 575.98px) {
      flex-direction: column;
    }
  }

  &__field-search {
    flex-grow: 1;
  }
}
