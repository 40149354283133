@import "utils/vars";

.icon-btn {
  position: relative;
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  background-color: transparent;
  color: $text-color-grey;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;

  &:hover,
  &:focus {
    color: $text-color-black;
  }
}
