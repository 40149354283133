@import "utils/vars";
@import "mixins/mixins";

.news {
  position: relative;
  display: flex;
  align-items: center;
  font-family: $futura-pt-book;
  cursor: grab;

  &:not(:last-child) {
    margin: 0 0 32px;
  }

  &__img {
    object-fit: contain;
    width: 180px;
    height: 120px;
  }

  &__info {
    flex-grow: 1;
    padding: 10px 30px;
  }

  &__title {
    margin: 0 0 8px;
    color: $text-color-black;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
  }

  &__link {
    text-decoration: none;
  }

  &__date {
    font-size: 15px;
  }
}

.news-preview {
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  margin: 0 0 2rem;

  .preview {
    &__img {
      object-fit: contain;
      width: 300px;
      height: 300px;

      @media (max-width: $mobile) {
        margin: 0 auto;
      }

      @media (max-width: 575.98px) {
        width: 100%;
      }
    }
  }
}
