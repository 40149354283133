@import "src/scss/utils/vars";

.btn {
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  min-width: 176px;
  margin: 0;
  padding: 7px;
  border: 1px solid transparent;
  border-radius: $border-radius;
  background-color: transparent;
  color: $text-color-grey;
  font-weight: 500;
  font-size: 16px;
  font-family: inherit;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;

  &:hover {
    color: $text-color-black;
  }

  &:focus {
    color: $text-color-grey;
  }

  &:active {
    color: $orange;
  }

  &__label {
    align-self: center;
    justify-self: center;
    width: 100%;
    text-align: center;
    pointer-events: none;
  }

  &_contained {
    border-color: $orange;
    background-color: $orange;
    color: $white;

    &:hover,
    &:focus {
      border-color: #f7927c;
      background-color: #f7927c;
      color: $white;
    }

    &:active {
      background-color: #ea6e52;
    }
  }

  &_outlined {
    border-color: #e0e0e0;
    background-color: $white;
    color: $text-color-black;

    &:hover,
    &:focus {
      border-color: $text-color-black;
    }

    &:focus {
      color: $text-color-black;
    }

    &:active {
      border-color: $orange;
      color: $orange;
    }
  }

  &_block {
    width: 100%;
  }

  &_disabled,
  &:disabled {
    @at-root a.btn_disabled {
      pointer-events: none;
    }

    border-color: #e0e0e0;
    background-color: #e0e0e0;
    color: #8c8c8c;
    cursor: not-allowed;
  }
}
