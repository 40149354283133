@import "utils/vars";

.toast {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  background-color: $orange;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  cursor: pointer;
}
