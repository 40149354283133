@import "utils/vars";
@import "mixins/mixins";

.news-form {
  margin: 0 0 32px;

  &__fieldset {
    display: grid;
    grid-gap: 16px 0;
    margin: 0 0 32px;
  }

  &__legend {
    margin: 0 0 8px;
    font-weight: 400;
    font-size: 32px;
    font-family: $futura-pt-book;
    line-height: 24px;
  }

  &__field-file {
    justify-self: flex-start;

    @media (max-width: 575.98px) {
      justify-self: stretch;
    }
  }
}
