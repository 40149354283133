@import "../utils/vars";
@import "../mixins/mixins";

.field-search {
  position: relative;
  z-index: 2;
  color: $white;

  &__input-wrap {
    position: relative;
    display: block;
  }

  &__input {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    min-height: 40px;
    margin: 0;
    padding: 8px 12px 8px 37px;
    border: none;
    border-radius: 8px;
    background-color: #f8f8fa;
    color: $text-color-grey;
    font-size: 18px;
    font-family: $futura-pt-book;
    line-height: 24px;
    letter-spacing: 0.05em;
    transition: border-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, width 0.3s ease;
    appearance: textfield;
    caret-color: #191919;

    &::placeholder {
      color: $text-color-grey;
    }

    &:focus {
      background-color: $white;
      box-shadow: 0 0 0 2px #fde4df;
      outline: none;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 11px;
    display: block;
    color: $text-color-grey;
    pointer-events: none;
    transition: color 0.3s ease;
    transform: translateY(-50%);
  }

  &__datalist {
    position: absolute;
    top: -9px;
    right: -9px;
    left: -9px;
    z-index: -1;
    padding: 65px 0 15px;
    border-radius: 8px;
    background-color: $white;
    box-shadow: 0 0 40px 0 rgb(159 161 171 / 25%);
  }

  &__list {
    @include list-unstyled;

    margin: 0;
  }

  &__link {
    display: block;
    overflow: hidden;
    padding: 8px 24px;
    color: $text-color-black;
    font-size: 16px;
    letter-spacing: 0.05em;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: background-color 0.3s ease;

    &:hover,
    &:focus {
      background-color: rgb(245 121 93 / 10%);
      color: $text-color-black;
    }
  }
}
