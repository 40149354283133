@import "vars";

.hidden {
  display: none !important;
}

.block {
  display: block !important;
}

.flex {
  display: flex !important;
}

.grid {
  display: grid !important;
}

@media (max-width: $tablet) {
  .tb\:hidden {
    display: none !important;
  }

  .tb\:block {
    display: block !important;
  }

  .tb\:flex {
    display: flex !important;
  }

  .tb\:grid {
    display: grid !important;
  }
}

@media (max-width: $mobile) {
  .mb\:hidden {
    display: none !important;
  }

  .mb\:block {
    display: block !important;
  }

  .mb\:flex {
    display: flex !important;
  }

  .mb\:grid {
    display: grid !important;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: none;
  white-space: nowrap;
  word-wrap: normal;
  clip-path: inset(100%);
}

.overflow-hidden {
  overflow: hidden;
}
