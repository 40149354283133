@import "utils/vars";
@import "mixins/mixins";

.dialog {
  &__header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 32px;
  }

  &__title {
    margin: 0;
    color: $black;
    font-weight: 400;
    font-size: 28px;
    font-family: $futura-pt-book;
    line-height: 36px;
    text-align: center;
  }

  &__body {
    flex-grow: 1;
    padding: 0 32px 32px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}

.delete-product-form,
.delete-user-form {
  &__field-actions {
    justify-content: center;
  }
}
