@import "../utils/vars";

.field-select {
  display: block;
  font-family: $futura-pt-book;

  &__label {
    display: block;
    color: #9fa1ab;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
  }

  &__select-wrap {
    position: relative;
    display: block;
  }

  &__select {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: 41px;
    margin: 0;
    padding: 8px 27px 8px 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #e0e0e0;
    border-left: 0;
    border-radius: 0;
    background-color: $white;
    color: $text-color-black;
    font-size: 18px;
    font-family: inherit;
    line-height: 24px;
    letter-spacing: 0.05em;
    cursor: pointer;
    appearance: none;

    &:focus {
      border-color: $green;
      outline: none;
    }

    &:disabled {
      border-bottom-style: dashed;
      color: $text-color-grey;
      cursor: not-allowed;

      & + .field-select {
        &__icon {
          color: $text-color-grey;
        }
      }
    }
  }

  &__icon {
    position: absolute;
    top: 7px;
    right: 0;
    color: $black;
  }

  &__help-text {
    display: block;
    padding: 8px 0 0;
    color: #191919;
    font-size: 14px;
    font-family: $futura-pt-book;
    line-height: 18px;
  }

  &_not-selected {
    .field-select {
      &__select {
        color: $text-color-grey;
      }
    }
  }

  &_error {
    .field-select {
      &__select {
        border-color: $red;
      }

      &__help-text {
        color: $red;
      }
    }
  }

  &_valid {
    .field-select {
      &__select {
        border-color: $green;
      }

      &__help-text {
        color: $green;
      }
    }
  }
}
