@import "vars";

@at-root {
  @viewport {
    width: device-width;
  }
}

.page {
  box-sizing: border-box;
  min-width: 320px;
  min-height: 100%;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  text-size-adjust: 100%;

  &__inner {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  &__content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  &__footer-wrapper {
    flex-shrink: 0;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  display: flex; // Исправляем баг в IE для min-height and flexbox (flex-direction:column)
  flex-direction: column; // и прижимаем footer в IE 10-11
  min-height: 100%;
  margin: 0;
  background-color: $body-bg;
  color: $text-color-black;
  font-size: $font-size;
  font-family: $font-family;
  line-height: $line-height;
  text-align: left;
  word-break: break-word;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

// Коррекция для кликабельных элементов с указанной ролью в iOS
[role="button"] {
  cursor: pointer;
}

// Мы любим Сафари (на самом деле, нет).
[type="search"]::-webkit-search-decoration {
  appearance: none;
}

input,
button,
textarea,
select {
  font: inherit;
}

// area,
// summary,
a,
button,
[role="button"],
input,
label,
select,
textarea {
  margin: 0; // Убираем margin в Firefox и Safari
  touch-action: manipulation; // Стилевая коррекция 300ms задержки (для поддерживающих браузеров)
}

button,
input {
  overflow: visible; // Покажем торчащее в Edge
  text-transform: none; // Уберём наследование text transform в Firefox
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  appearance: button; // Коррекция проблем стилизации для Safari
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  appearance: listbox; // Коррекция для Mobile Safari (https://github.com/twbs/bootstrap/issues/11266)
}

textarea {
  overflow: auto; // Удаление верт. скролла в IE.
  resize: vertical; // Правильное изменение размеров
}

svg {
  fill: currentcolor;

  a &,
  button & {
    pointer-events: none; // Мы любим Edge, это браузер! (почти)
  }

  &:not(:root) {
    overflow: hidden; // Скрыть выступающее за границы в IE
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  background-color: transparent;
  color: inherit;
  transition: color 0.3s ease;

  &:hover,
  &:focus {
    color: inherit;
  }
}

hr {
  overflow: visible; // Проблема Edge и IE
  box-sizing: content-box; // Проблема Firefox
  height: 0; // Проблема Firefox
  margin-top: $typo-margin-vertical * 2;
  margin-bottom: $typo-margin-vertical * 2;
  border: 0;
  border-top: 1px solid $border-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  color: currentcolor;
  font-weight: 700;
  line-height: 1.2;
}

h1 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

h4 {
  font-size: $font-size-h4;
}

h5 {
  font-size: $font-size-h5;
}

h6 {
  font-size: $font-size-h6;
}

p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: $typo-margin-vertical;
}

p {
  // http://clagnut.com/blog/2395
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
}

address {
  font-style: normal;
}

ul,
ol {
  margin-bottom: $typo-margin-vertical;
  padding-left: 1.5em;

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
  }
}

li {
  line-height: inherit;
}

// .small,
small {
  font-size: $font-size-sm;
}

sub,
sup {
  font-size: $font-size-sm;
}

b,
strong {
  font-weight: bolder;
}

blockquote {
  margin-right: 0;
  margin-left: 0;
  padding: 0;
}

// samp,
code,
kbd,
pre {
  font-family: $font-family--monospace;
}

code {
  padding: 0.06em 0.3em;
  border-radius: $border-radius;
  background-color: rgba(#000, 0.08);
  color: $text-color-black;
}

kbd {
  padding: 0.06em 0.3em;
  border-radius: $border-radius;
  background-color: $black;
  color: $text-color-black;

  kbd {
    padding: 0;
  }
}

pre {
  display: block;
  overflow-x: auto;
  width: 100%;
  margin-bottom: $typo-margin-vertical;
  background-color: rgba(#000, 0.08);
  tab-size: 2;

  code {
    background: transparent;
  }
}
