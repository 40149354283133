@import "utils/vars";
@import "mixins/mixins";

.admin-not-found {
  flex-grow: 1;

  &__link {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
