@import "utils/vars";
@import "mixins/mixins";

.admin-product {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 32px 0 0;

  &__wrapper {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 16px 24px;

    @media (max-width: $mobile) {
      grid-template-columns: auto;
      gap: 32px 0;
    }
  }
}
