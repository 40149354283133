@import "../utils/vars";

.field-file {
  display: block;

  &__name {
    display: block;
    margin: 0 0 8px;
    color: $text-color-grey;
    font-size: 12px;
    font-family: $futura-pt-book;
    line-height: 16px;
    text-transform: uppercase;
  }

  &__input {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    white-space: nowrap;
    clip-path: inset(50%);
  }

  &__help-text {
    display: block;
    padding: 8px 0 0;
    color: #191919;
    font-size: 14px;
    font-family: $futura-pt-book;
    line-height: 18px;
  }

  &__btn {
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-sizing: border-box;
    min-width: 176px;
    margin: 0;
    padding: 7px;
    border: 1px solid transparent;
    border-radius: $border-radius;
    background-color: transparent;
    color: $text-color-grey;
    font-weight: 500;
    font-size: 16px;
    font-family: inherit;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;

    @media (max-width: $mobile) {
      width: 100%;
    }

    &_contained {
      border-color: $orange;
      background-color: $orange;
      color: $white;

      &:hover,
      &:focus {
        border-color: #f7927c;
        background-color: #f7927c;
        color: $white;
      }

      &:active {
        background-color: #ea6e52;
      }
    }
  }

  &_error {
    .field-file {
      &__help-text {
        color: $red;
      }
    }
  }
}
