@import "utils/vars";

.burger {
  position: relative;
  display: none;
  padding: 8px 4px;
  border: none;
  background-color: $white;
  cursor: pointer;

  @media (max-width: $mobile) {
    display: block;
  }

  &__inner {
    display: block;
    width: 24px;
    height: 16px;
  }

  &__line {
    position: absolute;
    top: 50%;
    display: block;
    width: 17px;
    height: 2px;
    margin: -1px 0 0;
    border-radius: 2px;
    background-color: $text-color-black;
    transition-duration: 100ms;
    transition-property: transform;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 24px;
      height: 2px;
      border-radius: 2px;
      background-color: $text-color-black;
      transition-property: transform;
    }

    &::before {
      top: -7px;
      transition: top 100ms ease 0.12s, opacity 100ms ease;
    }

    &::after {
      bottom: -7px;
      transition: bottom 100ms ease 0.12s, transform 100ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &_open {
    .burger {
      &__line {
        width: 24px;
        transform: rotate(45deg);

        &::before {
          top: 0;
          opacity: 0;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
        }
      }
    }
  }
}
