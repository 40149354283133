@import "../utils/vars";
@import "../mixins/mixins";

.field-actions {
  display: flex;

  @media (max-width: $mobile) {
    flex-direction: column;
  }

  &__btn {
    &:not(:last-child) {
      margin: 0 24px 0 0;

      @media (max-width: $mobile) {
        margin: 0 0 16px;
      }
    }
  }
}
