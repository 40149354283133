@import "utils/vars";
@import "mixins/mixins";

.report {
  font-family: $futura-pt-book;

  &:not(:last-child) {
    @include gradient-dotted;
  }

  &__header {
    display: flex;
  }

  &__badge {
    display: flex;
    padding: 4px 10px 4px 0;
  }

  &__icon {
    &_close {
      color: #22863a;
    }

    &_open {
      color: $orange;
    }
  }

  &__user,
  &__link,
  &__btn {
    color: $orange;
  }

  &__link {
    text-decoration: none;
  }

  &__btn {
    margin: 0;
    padding: 0;
    border: none;
    background-color: inherit;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover,
    &:focus {
      color: $text-color-black;
    }

    &:active {
      color: $orange;
    }
  }

  &__list {
    list-style-type: "🚫";
  }
}
