@import "utils/vars";
@import "mixins/mixins";

.requests-list {
  flex-grow: 1;
  margin: 0 0 60px;

  @media (max-width: $mobile) {
    margin: 0 0 40px;
  }
}
