@import "utils/vars";

.product {
  &__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    border-radius: 8px;
    background-color: $white;
    transition: box-shadow 0.3s ease;

    @media (max-width: $mobile) {
      padding: 16px 8px;
    }

    &:hover,
    &:focus-within {
      box-shadow: 0 12px 25px rgb(159 161 171 / 25%);

      .product {
        &__link {
          color: $orange;
        }
      }
    }
  }

  &__img-wrap {
    flex-shrink: 0;
    margin: 0 0 1rem;
  }

  &__img {
    object-fit: contain;
    width: 100%;
    height: 182px;

    @media (max-width: $mobile) {
      height: 148px;
    }
  }

  &__title {
    flex-grow: 1;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    font-family: $futura-pt-book;
    line-height: 20px;
    letter-spacing: 0.05em;

    @media (max-width: $mobile) {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: normal;
    }
  }

  &__link {
    display: -webkit-box;
    overflow: hidden;
    color: #263030;
    text-decoration: none;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__actions {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
  }
}

.product-preview {
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  align-items: center;

  @media (max-width: $mobile) {
    align-items: stretch;
  }

  .preview {
    &__img {
      object-fit: contain;
      width: 300px;
      height: 300px;

      @media (max-width: $mobile) {
        margin: 0 auto;
      }

      @media (max-width: 575.98px) {
        width: 100%;
      }
    }
  }
}
