@import "utils/vars";

.loader {
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($black, 0.2);

  &__circle {
    display: inline-block;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border: 5px solid $orange;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: rotation 1s linear infinite;
  }
}
