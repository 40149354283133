@import "utils/vars";
@import "mixins/mixins";

.shelf-form {
  grid-column: 2;
  margin: 0 0 32px;

  @media (max-width: $mobile) {
    grid-column: auto;
  }

  &__fieldset {
    display: grid;
    grid-gap: 16px;
    margin: 0 0 32px;
  }

  &__legend {
    margin: 0 0 8px;
    font-weight: 400;
    font-size: 32px;
    font-family: "Futura PT Book", sans-serif;
    line-height: 24px;
  }
}
