@import "utils/vars";

.page-footer {
  padding: 16px 0;
  background-color: $text-color-black;

  &__copyright {
    color: $text-color-grey;
    font-size: 18px;
    font-family: $futura-pt-book;
    line-height: 24px;
    letter-spacing: 0.05em;
  }
}
