@import "utils/vars";
@import "mixins/mixins";

.brands {
  margin: 0 0 60px;

  &__body {
    @media (max-width: $mobile) {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-auto-flow: column;
      grid-column-gap: 3px;
    }
  }

  &__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media (max-width: $mobile) {
      position: sticky;
      top: 0;
      flex-direction: column;
      grid-column: 2 / -1;
      justify-content: flex-start;
      overflow-y: auto;
      width: 35px;
      height: 100vh;
    }
  }

  &__link {
    display: block;
    margin: 0 0 8px;
    padding: 8px;
    border-radius: 8px;
    color: $text-color-grey;
    font-size: 18px;
    font-family: $futura-pt-book;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    @media (max-width: $tablet) {
      font-size: 16px;
      line-height: 20px;
    }

    @media (max-width: $mobile) {
      margin: 0;
      padding: 0 2px;
      font-size: 14px;
    }

    &_active {
      background-color: #f8f8fa;
      color: $text-color-black;

      @media (max-width: $mobile) {
        background-color: transparent;
        color: $orange;
      }
    }
  }

  &__section {
    &:not(:last-child) {
      margin: 0 0 40px;

      @media (max-width: $mobile) {
        margin: 0 0 20px;
      }
    }
  }

  &__title {
    margin: 0 0 32px;
    color: $orange;
    font-weight: 400;
    font-size: 32px;
    font-family: $futura-pt-book;
    line-height: 24px;

    @media (max-width: $mobile) {
      margin: 0 0 10px;
      line-height: 40px;
    }
  }

  &__list {
    column-count: 5;
    column-gap: 32px;

    @media (max-width: $tablet) {
      column-count: 3;
    }

    @media (max-width: $mobile) {
      column-count: 1;
    }
  }

  &__name {
    display: block;
    color: $text-color-black;
    font-size: 18px;
    font-family: $futura-pt-book;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-decoration: none;

    @media (max-width: $mobile) {
      @include gradient-dotted;

      padding: 14px 0;
    }

    &:hover {
      color: $orange;
    }
  }
}
