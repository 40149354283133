@import "utils/vars";
@import "mixins/mixins";

.keys {
  &__list {
    @include list-unstyled;

    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin: 0 0 32px;
  }

  &__item {
    padding: 4px;
    border-radius: 10px;
    background-color: $orange;
    color: $white;
  }
}
