@import "utils/vars";
@import "utils/reset";
@import "utils/libs";
@import "utils/atomic";
@import "utils/print";
@import "loader";
@import "wrapper";
@import "icon";
@import "icon-btn";
@import "form/form";
@import "form/field-search";
@import "form/field-text";
@import "form/field-select";
@import "form/field-file";
@import "form/field-actions";
@import "page-header";
@import "burger";
@import "main-nav";
@import "mobile-menu";
@import "page-body";
@import "toolbar";
@import "product";
@import "user";
@import "ingredient";
@import "catalog-list";
@import "users-list";
@import "requests-list";
@import "reports-list";
@import "news-list";
@import "ingredients-list";
@import "news";
@import "pagination";
@import "dialog";
@import "collapse";
@import "page-footer";
@import "loading";
@import "product-form";
@import "brand-form";
@import "news-form";
@import "seo-form";
@import "shelf-form";
@import "report";
@import "request";
@import "welcome";
@import "keys";
@import "brands";
@import "user-card";
@import "admin-products";
@import "admin-product";
@import "admin-add-product";
@import "admin-search-result";
@import "admin-users";
@import "reports";
@import "requests";
@import "admin-news";
@import "admin-article";
@import "admin-brands";
@import "admin-brand";
@import "admin-add-news";
@import "admin-add-brand";
@import "admin-user";
@import "admin-seo";
@import "admin-not-found";
@import "admin-ingredients";
@import "toasts";
@import "editor";

body {
  font-family: $futura-pt;
}
