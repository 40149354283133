@import "utils/vars";

.catalog-list {
  flex-grow: 1;
  margin: 0 0 60px;

  @media (max-width: $mobile) {
    margin: 0 0 40px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(5, minmax(auto, 1fr));
    grid-gap: 32px;

    @media (max-width: $tablet) {
      grid-template-columns: repeat(3, minmax(auto, 1fr));
    }

    @media (max-width: 575.98px) {
      grid-template-columns: repeat(2, minmax(auto, 1fr));
      grid-gap: 16px;
    }
  }
}
